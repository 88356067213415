<template>
  <HeaderRow
    message="Wir Heiraten"
    date="31 | 05 | 25"
    name="Daniel & Marissa"
  />
  <ImageBanner></ImageBanner>
  <div class="spacer"></div>
  <nav>
    <RouterLink activeClass="active" to="/">Einladung</RouterLink>
    <RouterLink activeClass="active" to="/Tagesablauf">Tagesablauf</RouterLink>
    <RouterLink activeClass="active" to="/Locations">Locations</RouterLink>
    <RouterLink activeClass="active" to="/Unterkunft">Unterkunft</RouterLink>
    <RouterLink activeClass="active" to="/Rückmeldung">Rückmeldung</RouterLink>
    <RouterLink activeClass="active" to="/FAQ">Good To Know</RouterLink>
    <RouterLink activeClass="active" to="/Fotos">Fotos</RouterLink>
    <RouterLink activeClass="active" to="/Kontakt">Kontakt</RouterLink>
  </nav>
  <div class="spacer"></div>
  <main class="content">
    <RouterView />
  </main>
  <Countdown :date="new Date('May 31, 2025 12:00:00')" />
</template>

<script>
import HeaderRow from "./components/HeaderRow.vue";
import ImageBanner from "./components/ImageBanner.vue";
import Countdown from "./components/Countdown.vue";
import { RouterLink, RouterView } from "vue-router";

export default {
  name: "App",
  components: {
    HeaderRow,
    ImageBanner,
    RouterLink,
    RouterView,
    Countdown,
  },
};
</script>

<style scoped>
.content {
  font-family: "JosefineSansSemiBold", regular;
  background-color: rgba(255, 255, 255, 0.6);
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h3 {
  font-size: 28px;
  font-family: "Brittany", cursive;
}

nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

a {
  font-size: 18px;
  text-decoration: none;
  padding: 10px 20px;
  color: #000000;
  font-family: "JosefineSansSemiBold", regular;
  transition: background-color 0.3s, color 0.3s;
}

a:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
  color: #fff;
}

.active {
  text-decoration: underline;
  text-underline-offset: 5px;
  color: #8ea16b;
  font-weight: bolder;
}

.spacer {
  margin: 20px;
}

button {
  background-color: #b5651d;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  font-family: "Great Vibes", cursive;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #8b4513;
}

.card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 20px 0;
  border: 1px solid #f0e68c; /* Light khaki border */
}
</style>
