import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/main.css";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import "primeicons/primeicons.css";

const app = createApp(App);
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: { darkModeSelector: ".fake-dark-selector" },
  },
});

app.use(router).mount("#app");
