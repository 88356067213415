<template>
  <h3>Countdown</h3>
  <div class="container">
    <div class="dateContainer days">
      <span>{{ days }}</span>
      <span>Tage</span>
    </div>
    <div class="dateContainer hours">
      <span>{{ hours }}</span>
      <span>Stunden</span>
    </div>
    <div class="dateContainer minutes">
      <span>{{ minutes }}</span>
      <span>Minuten</span>
    </div>
    <div class="dateContainer seconds">
      <span>{{ seconds }}</span>
      <span>Sekunden</span>
    </div>
  </div>
</template>

<script>
const _second = 1000;
const _minute = _second * 60;
const _hour = _minute * 60;
const _day = _hour * 24;
export default {
  name: "CountdownComponent",
  props: { date: Date },
  data() {
    return {
      now: Date.now(),
    };
  },
  computed: {
    timeLeft() {
      return this.date - this.now;
    },
    days() {
      return Math.floor(this.timeLeft / _day);
    },
    hours() {
      return Math.floor((this.timeLeft % _day) / _hour);
    },
    minutes() {
      return Math.floor((this.timeLeft % _hour) / _minute);
    },
    seconds() {
      return Math.floor((this.timeLeft % _minute) / _second);
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.now = Date.now();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dateContainer {
  display: flex;
  font-family: JosefineSansSemiBold;
  flex-direction: column;
}
h3 {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 24px;
}
.container {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-bottom: 10px;
}
</style>
