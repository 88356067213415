<template>
  <div class="image" :style="cssProps" />
</template>

<script>
export default {
  name: "ImageBanner",
  props: {},
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require("@/assets/MarissaAndDaniel.jpg")})`,
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image {
  /* max-height: 600px; */
  height: 40vh;
  background-position: center 35%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid white;
}
</style>
