<template>
  <div class="container">
    <span class="element message">{{ message }}</span>
    <span class="element date"> {{ date }}</span>
    <span class="element name"> {{ name }}</span>
  </div>
</template>

<script>
export default {
  name: "HeaderRow",
  props: {
    message: String,
    date: String,
    name: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span {
  font-size: 24px;
}

.date {
  font-family: "JosefineSansSemiBold", regular;
}
.container {
  display: flex;
  margin: 10px;
}
.element {
  flex: 1 1 200px;
}
.message {
  text-align: left;
  margin-left: 20px;
}
.name {
  text-align: right;
  margin-right: 20px;
}
</style>
