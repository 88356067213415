import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import Einladung from "../views/EinladungsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "EinladungsView",
    component: Einladung,
  },
  {
    path: "/Tagesablauf",
    name: "Tagesablauf",
    component: () => import("../views/TagesablaufView.vue"),
  },
  {
    path: "/Locations",
    name: "Locations",
    component: () => import("../views/LocationsView.vue"),
  },
  {
    path: "/Unterkunft",
    name: "Unterkunft",
    component: () => import("../views/UnterkunftsView.vue"),
  },
  {
    path: "/Rückmeldung",
    name: "Rückmeldung",
    component: () => import("../views/RückmeldungsView.vue"),
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: () => import("../views/FAQView.vue"),
  },
  {
    path: "/Fotos",
    name: "Fotos",
    component: () => import("../views/FotosView.vue"),
  },
  {
    path: "/Kontakt",
    name: "Kontakt",
    component: () => import("../views/KontaktView.vue"),
  },
  {
    path: "/Auswertung",
    name: "Auswertung",
    component: () => import("../views/AuswertungsView.vue"),
  },
];

// history: createWebHistory(process.env.BASE_URL),
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
